<template>
  <div class="card" style="margin-top: 0">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">notes</i>
      </div>
      <h3 class="card-title">Detalle para Facturacion</h3>
    </div>
    <div class="card-body">
      <v-row class="mx-0 my-0 px-0 py-0">
        <v-col cols="2">
          <v-select
            v-model="opcionReporte"
            :items="[
              { id: 1, nombre: 'Calendario' },
              { id: 2, nombre: 'Financiero' },
            ]"
            item-text="nombre"
            item-value="id"
            label="Periodo"
            color="blue darken-3"
          ></v-select>
        </v-col>
        <v-col cols="2" v-if="opcionReporte == 2">
          <v-select
            v-model="facturacion.cierre"
            :items="cierres"
            item-text="nombre"
            item-value="id"
            placeholder="Elija periodo de cierre"
            label="Elija periodo de cierre"
            color="blue darken-3"
            @change="setCierre"
            return-object
          ></v-select>
        </v-col>

        <v-col cols="2" v-if="opcionReporte != 2">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="inicio_formated"
                label="Fecha de Inicio"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="inicio"
              @input="menu1 = false"
              locale="es-cl"
              prev-icon="chevron_left"
              next-icon="chevron_right"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" v-if="opcionReporte != 2">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="termino_formated"
                label="Fecha de termino"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="termino"
              @input="menu2 = false"
              locale="es-cl"
              prev-icon="chevron_left"
              next-icon="chevron_right"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="facturacion.empresa"
            :items="empresas"
            item-text="razon_social"
            item-value="id"
            placeholder="Cliente"
            label="Cliente"
            color="blue darken-3"
            persistent-hint
            return-object
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <!-- <v-select
            prepend-icon="mdi-navigation"
            v-model="filtro"
            :items="[
              { id: 1, nombre: 'Todos' },
              { id: 2, nombre: 'Cosecha' },
              { id: 3, nombre: 'Smolt' },
            ]"
            item-text="nombre"
            item-value="id"
            label="Filtro Reporte"
            color="blue darken-3"
            dense
          ></v-select> -->
          <v-select
            prepend-icon="mdi-navigation"
            v-model="facturacion.tipo_partes_id"
            :items="[
              //{ id: 0, nombre: 'Todos' },
              { id: 1, nombre: 'Cosecha' },
              { id: 3, nombre: 'Smolt' },
              { id: 4, nombre: 'Mortalidad' },
              { id: 5, nombre: 'Baño' },
              { id: 6, nombre: 'Vacuna' },
            ]"
            item-text="nombre"
            item-value="id"
            label="Filtro Reporte"
            color="blue darken-3"
          ></v-select>
        </v-col>
        <v-col class="text-right" cols="2">
          <v-btn color="blue darken-1" dark @click="loadPartes"
            ><i class="material-icons">cached</i> Generar</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="mx-1 mb-4 py-0">
        <v-col cols="9" class="px-3 my-0 py-0">
          <v-row class="border border-dark mr-0">
            <v-col cols="8" class="my-0 py-0">
              <p>Transporte Maritimo</p>
              <p>Casa Matriz: Mar del Plata N° 2111, Providencia, Santiago</p>
              <p>Punta Pureo S/N, San José - Calbuco</p>
            </v-col>
            <v-col cols="4" class="text-right my-0 py-0">
              <p>Teléfono: (56)(65)2461471</p>
              <p>Fax: (56)(65)2461072</p>
              <p style="margin-bottom: 10px">
                Correo electrónico: dcerda@lapsa.cl
              </p>
            </v-col>
          </v-row>
        </v-col>
        <p></p>
        <v-col cols="3" class="my-0 py-0">
          <v-row class="border border-dark text-center mr-n2">
            <v-col cols="12" class="my-0 py-0">
              <p>RUT 94.365.000-4</p>
              <p>Prefactura N°</p>
              <i
                v-if="facturacion.id && facturacion.temporal === 0"
                class="material-icons"
                style="color:orange"
              >
                warning
              </i>
              <b style="font-size: 16pt">{{ this.facturacion.numero }}</b>
              <!-- <i class="material-icons" style="color: orange;">warning_amber</i> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mx-1 mb-1 py-0 mr-5">
        <v-col cols="9" class="border border-dark my-0 py-0">
          <v-row class="my-0 py-0">
            <v-col cols="1" class="my-0 py-0">
              <p>Cliente</p>
              <p>Rut</p>
              <p>Giro</p>
            </v-col>
            <v-col cols="5" class="my-0 py-0">
              <p v-if="facturacion.empresa">
                {{ facturacion.empresa.razon_social }}
              </p>
              <p v-if="facturacion.empresa">{{ facturacion.empresa.rut }}</p>
              <p v-if="facturacion.empresa">{{ facturacion.empresa.giro }}</p>
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <p v-if="facturacion.empresa">
                Dirección: {{ facturacion.empresa.direccion }}
              </p>
              <p v-if="facturacion.empresa">
                Teléfono : {{ facturacion.empresa.fono }}
              </p>
              <p v-if="facturacion.empresa">
                Correo Electrónico: {{ facturacion.empresa.email }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="3"
          class="border border-dark text-right my-0 py-0 mr-n9 ml-3"
        >
          <v-row class="my-0 py-0">
            <v-col class="my-0 py-0">
              <p>Fecha Emisión: {{ formatDate(facturacion.emision) }}</p>
              <p>Fecha Vencimiento:{{ formatDate(facturacion.vencimiento) }}</p>
              <p>
                {{ this.facturacion.dias }} días a contar de la fecha de emisión
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <spinner v-if="spinner"></spinner>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="partesListHeaders"
            :items="partesListBody"
            class="elevation-1 mx-0"
            no-data-text="No hay partes"
            fixed-header
            :height="table_height"
            :search="search"
            :header-props="{ sortIcon: 'arrow_upward' }"
            dense
            :hide-default-footer="true"
            disable-pagination
          >
            <template v-slot:item="{ item }">
              <tr
                :class="`${item.select ? 'blue-grey lighten-4' : ''}`"
                :key="item.id"
                v-on:dblclick="item.select = !item.select"
              >
                <td>
                  <a @click="downloadPDF(item)" v-if="item.pdf != null"
                    ><i class="material-icons">picture_as_pdf</i></a
                  >
                </td>
                <!-- <td class="text-center">
                  <a @click="saveItem(item)"><i class="material-icons">save</i></a>
                </td> -->
                <td class="text-center">
                  <span>{{ item.zarpe | format_fecha }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.wellboat }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.guia }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.origen }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.jaula_origen }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.destino }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.jaula_destino }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.especie }}</span>
                </td>
                <td class="text-center">
                  <span>{{ formatNumber(item.peces, 0) }}</span>
                </td>
                <td class="text-center">
                  <span>{{ formatNumber(item.peso, 2) }}</span>
                </td>
                <td class="text-center">
                  <span>{{ formatNumber(item.biomasa, 0) }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.espera_carga }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.tiempo_carga }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.espera_traslado }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.tiempo_traslado }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.espera_descarga }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.tiempo_descarga }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.horas }}</span>
                </td>
                <td class="text-center">
                  <v-text-field
                    v-model="item.tarifa_viaje"
                    single-line
                    type="number"
                    @change="calcularTarifa2(item)"
                  />
                </td>
                <td class="text-center">
                  <v-text-field
                    v-model="item.demurrage"
                    placeholder="Demurrage"
                    @change="calcularTarifa2(item)"
                    class="text-right"
                    :rules="[demurrageRule]"
                  ></v-text-field>
                </td>
                <td class="text-center">
                  <span>{{ formatNumber(item.total, 0) }}</span>
                </td>
                <td class="text-center">
                  <v-text-field
                    v-model="item.digitar_millas"
                    placeholder="Millas"
                    @change="calcularTarifa(item)"
                    v-on:keyup.enter="calcularTarifa(item)"
                    class="text-right"
                    :rules="[millasRule]"
                  ></v-text-field>
                </td>
                <td class="text-center">
                  <span>{{ formatNumber(item.millas,0) }}</span>
                </td>
                <td class="text-center">
                  <v-text-field
                    v-model="item.observaciones"
                    placeholder="Observaciones"
                  ></v-text-field>
                </td>
              </tr>
            </template>
            <!-- <template v-slot:item.id="{ item }">
              <a @click="saveItem(item)"><i class="material-icons">save</i></a>
            </template>
            <template v-slot:item.zarpe="{ item }">
              <span>{{ item.zarpe | format_fecha }}</span>
            </template>
            <template v-slot:item.peces="{ item }">
              <span>{{ formatNumber(item.peces, 0) }}</span>
            </template>
            <template v-slot:item.peso="{ item }">
              <span>{{ formatNumber(item.peso, 2) }}</span>
            </template>
            <template v-slot:item.biomasa="{ item }">
              <span>{{ formatNumber(item.biomasa, 0) }}</span>
            </template>
            <template v-slot:item.tarifa_viaje="{ item }">
              <v-text-field
                v-model="item.tarifa_viaje"
                single-line
                type="number"
                @change="calcularTarifa2(item)"
              />
            </template>
            <template v-slot:item.total="{ item }">
              <span>{{ formatNumber(item.total) }}</span>
            </template>
            <template v-slot:item.demurrage="{ item }">
              <v-text-field
                v-model="item.demurrage"
                placeholder="Demurrage"
                @change="calcularTarifa2(item)"
                class="text-right"
                :rules="[demurrageRule]"
              ></v-text-field>
            </template>
            <template v-slot:item.digitar_millas="{ item }">
              <v-text-field
                v-model="item.digitar_millas"
                placeholder="Millas"
                @change="calcularTarifa(item)"
                class="text-right"
                :rules="[millasRule]"
              ></v-text-field>
            </template>
            <template v-slot:item.millas="{ item }">
              <span>{{ formatNumber(item.millas) }}</span>
            </template>
            <template v-slot:item.observaciones="{ item }">
              <v-text-field
                v-model="item.observaciones"
                placeholder="Observaciones"
              ></v-text-field>
            </template> -->
            <template slot="body.append">
              <tr class="primary--text">
                <th class="title text-left" colspan="11">
                  {{ this.facturacion.total_letras }} DOLARES
                </th>
                <th class="title text-center">
                  {{ formatNumber(sumaColumna("biomasa"), 0) }}
                </th>
                <th class="title text-left" colspan="9"></th>
                <th class="title text-center">
                  {{ formatNumber(parseInt(sumaColumna("total")), 0) }}
                </th>
                <th></th>
                <th></th>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- <v-row> -->
      <!-- <v-col cols="8">


        </v-col> -->
      <!-- <v-col cols="8" class="text-right"> -->
      <!-- Se oculta botón de subir facturación
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                color="green darken-1"
                dark
                right
                fab
                small
                @click="upload = !upload"
                v-bind="attrs"
                v-on="on"
              >
                <i class="material-icons">upload</i>
              </v-btn>
            </template>
            <span>Subir excel histórico de facturación</span>
          </v-tooltip>
          -->
      <!-- <v-btn @click="saveFactura(1)">Guardar Cambios</v-btn>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn color="primary" @click="saveFactura(0)"><i class="material-icons">save</i> Generar Detalle</v-btn>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            @click="recalcularTarifa"
            :disabled="partesListBody.length == 0"
            class="mr-6 bg-yellow"
            >Recalcular Tarifa</v-btn
          >

          <v-btn @click="saveFactura(1)" class="mr-6">Guardar Cambios</v-btn>

          <!--<v-btn 
            color="primary" 
            @click="saveFactura(0)"
            :disabled="facturacion.id&&facturacion.temporal===0?true:false"
          >
            <i class="material-icons">save</i> 
            Generar Detalle
          </v-btn>-->

          <v-btn color="primary" @click="saveFactura(0)">
            <i class="material-icons">save</i>
            Generar Detalle
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Modal upload -->
    <v-dialog v-model="upload" width="600px">
      <v-card>
        <v-card-title class="green darken-1">
          Subir datos de facturación por cierre desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col>
              <v-select
                v-model="cierre"
                :items="cierres"
                item-text="nombre"
                item-value="id"
                placeholder="Cierre Financiero"
                label="Cierre Financiero"
                color="blue darken-3"
                persistent-hint
                return-object
              ></v-select>
            </v-col>
            <v-col>
              <v-file-input
                v-model="file"
                accept=".xls, .xlsx"
                label="Archivo Excel"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="upload = false">Cancelar</v-btn>
          <v-btn color="primary" @click="uploadFacturas">Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
  </div>
</template>
<style scoped>
.bg-yellow {
  background-color: #f48c06 !important;
}
.card {
  margin-top: 0;
}

.card .card-header-rose .card-icon {
  background: #263238;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(32, 30, 31, 0.822);
}

.v-application p {
  margin-bottom: 1px;
}
</style>
<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    spinner: false,
    opcionReporte: 2,
    filtro: 1,
    upload: false,
    color: "green",
    snackbar: false,
    validation_message: "",
    search: null,
    years: [2020, 2021],
    table_height: 550,
    cierres: [],
    file: null,

    millasRule: (v) => {
      if (!isNaN(parseFloat(v)) && v >= 0) return true;
      return "Número Inválido";
    },
    demurrageRule: (v) => {
      if (!isNaN(parseFloat(v)) && v >= 0) return true;
      return "Número Inválido";
    },
    months: [
      { id: 1, name: "Enero" },
      { id: 2, name: "Febrero" },
      { id: 3, name: "Marzo" },
      { id: 4, name: "Abril" },
      { id: 5, name: "Mayo" },
      { id: 6, name: "Junio" },
      { id: 7, name: "Julio" },
      { id: 8, name: "Agosto" },
      { id: 9, name: "Septiembre" },
      { id: 10, name: "Octubre" },
      { id: 11, name: "Noviembre" },
      { id: 12, name: "Diciembre" },
    ],
    empresas: [],
    cierre: {},
    facturacion: {
      id: null,
      temporal: 1,
      year: 2020,
      month: { id: 12, name: "Diciembre" },
      empresa: null,
      numero: 0,
      total_letras: 0,
      fecha: "",
      emision: "",
      vencimiento: "",
      dias: 0,
      cierre: {},
      tipo_partes_id: null,
      url_pdf: "",
    },
    inicio: null,
    termino: null,
    partesListHeaders: [
      /* {
        text: "Acciones",
        align: "center",
        sortable: true,
        value: "id",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      }, */
      {
        text: "Documento",
        align: "center",
        sortable: true,
        value: "zarpe",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Fecha Viaje",
        align: "center",
        sortable: true,
        value: "zarpe",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Wellboat",
        align: "center",
        sortable: true,
        value: "wellboat",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Guia despacho",
        align: "center",
        sortable: true,
        value: "guia",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Centro Origen",
        align: "center",
        sortable: true,
        value: "origen",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Jaula Origen",
        align: "center",
        sortable: true,
        value: "jaula_origen",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Centro Destino",
        align: "center",
        sortable: true,
        value: "destino",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Jaula Destino",
        align: "center",
        sortable: true,
        value: "jaula_destino",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Especie",
        align: "center",
        sortable: true,
        value: "especie",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Wellboat",
        align: "center",
        sortable: true,
        value: "peces",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Talla Pez Informe Centro (Kg)",
        align: "center",
        sortable: true,
        value: "peso",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Total Carga Biomasa (Kg)",
        align: "center",
        sortable: true,
        value: "biomasa",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Carga-Espera",
        align: "center",
        sortable: true,
        value: "espera_carga",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Carga-Duración",
        align: "center",
        sortable: true,
        value: "tiempo_carga",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Traslado-Espera",
        align: "center",
        sortable: true,
        value: "espera_traslado",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Traslado-Duracion",
        align: "center",
        sortable: true,
        value: "tiempo_traslado",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Descarga-Espera",
        align: "center",
        sortable: true,
        value: "espera_descarga",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempo Descarga-Duracion",
        align: "center",
        sortable: true,
        value: "tiempo_descarga",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Total Horas",
        align: "center",
        sortable: true,
        value: "horas",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tarifa Viaje USD",
        align: "center",
        sortable: true,
        value: "tarifa_viaje",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Demurrage USD",
        align: "center",
        sortable: true,
        value: "demurrage",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Servicio USD",
        align: "center",
        sortable: true,
        value: "total",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Digitar MN",
        align: "center",
        sortable: true,
        value: "digitar_millas",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "MN",
        align: "right",
        sortable: true,
        value: "millas",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Observaciones",
        align: "center",
        sortable: false,
        value: "observaciones",
        width: "250px",
        class: "blue-grey darken-4 white--text",
      },
    ],
    partesListBody: [],
  }),
  async mounted() {
    await this.loadEmpresas();
    await this.loadCierres();
    //this.facturacion.empresa = this.empresas[0];
    //this.facturacion.month = this.months[this.moment().format("M") - 1];
    //let year = this.moment().format("YYYY");
    //this.years = [year - 1, year];
    //this.facturacion.year = this.years[1];
    //if (window.innerHeight < 768) {
    //  this.table_height = 400;
    //}
    //this.facturacion.fecha = this.moment().format("YYYY-MM-DD");
    //this.facturacion.emision = this.moment().format("YYYY-MM-DD");
    //this.facturacion.vencimiento = this.moment(this.facturacion.emision)
    //  .add(this.facturacion.dias, "days")
    //  .format("YYYY-MM-DD");
    await this.loadFacturaTemporal();
  },
  // URL PDF PUNTERO
  methods: {
    async loadFacturaTemporal() {
      this.url_pdf = "";
      let url = `${this.base_url}Facturacion/porfecha/${this.inicio}/${this.termino}/${this.cierre.id}/${this.opcionFiltro}`;
      //let url = `${this.base_url}factura/temporal`;
      await this.axios.get(url, this.headers_json).then((r) => {
        if (r.data.data != null) {
          this.facturacion.id = r.data.data.id;
          this.facturacion.temporal = r.data.data.temporal;
          this.facturacion.empresa = r.data.data.empresa;
          this.facturacion.cierre = r.data.data.cierre;
          this.facturacion.fecha = r.data.data.fecha;
          this.facturacion.emision = r.data.data.emision;
          this.facturacion.numero = r.data.data.numero;
          this.facturacion.tipo_partes_id = r.data.data.tipo_partes_id;
          this.loadAcuerdo();
          this.partesListBody = r.data.data.detalles;
          let total_usd = parseInt(this.sumaColumna("total"));
          this.facturacion.total_letras = this.numeroALetras(total_usd).replace(
            "TEINTA",
            "TREINTA"
          );
        } else {
          this.facturacion.empresa = this.empresas[0];
          //this.facturacion.month = this.months[this.moment().format("M") - 1];
          let year = this.moment().format("YYYY");
          this.years = [year - 1, year];
          //this.facturacion.year = this.years[1];
          if (window.innerHeight < 768) {
            this.table_height = 400;
          }
          console.log("entra aqui");
          this.facturacion.fecha = this.moment().format("YYYY-MM-DD");
          this.facturacion.emision = this.moment().format("YYYY-MM-DD");
          this.facturacion.vencimiento = this.moment(this.facturacion.emision)
            .add(this.facturacion.dias, "days")
            .format("YYYY-MM-DD");
        }
        //console.log(r.data.data)
      });
    },
    setCierre() {
      this.inicio = this.formatIso(this.facturacion.cierre.inicio);
      this.termino = this.formatIso(this.facturacion.cierre.fin);
    },
    formatIso(fecha) {
      let arrF = fecha.split("-");
      return `${arrF[2]}-${arrF[1]}-${arrF[0]}`;
    },
    sumaColumna(key) {
      let total = this.partesListBody.reduce(
        (a, b) => parseFloat(a) + (parseFloat(b[key]) || 0),
        0
      );
      if (key == "total") {
        this.facturacion.total_letras = this.numeroALetras(total).replace(
          "TEINTA",
          "TREINTA"
        );
      }
      return total;
    },
    async loadCierres() {
      let url = `${this.base_url}cierres/lista/select`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.cierres = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // ABRE VENTANA NUEVA CON PDF
    downloadPDF(item) {
      let st_url = this.storage_url.replace("public", "docs");
      let pdf_url = st_url + item.pdf;
      console.log(pdf_url);
      window.open(pdf_url);
    },

    async loadEmpresas() {
      let url = `${this.base_url}empresas/lista/select`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.empresas = response.data;
          this.facturacion.empresa = this.empresas[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadPartes() {
      this.spinner = true;

      //this.mostrarLoading("Cargando listado de viajes...");
      this.partesListBody = [];
      let cierre_id = 0;
      if (this.opcionReporte == 2) {
        cierre_id = this.facturacion.cierre.id;
      }
      let url = `${this.base_url}partes/porcliente/${cierre_id}/${this.inicio}/${this.termino}/${this.facturacion.empresa.id}/${this.facturacion.tipo_partes_id}`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          let partes = response.data.body;
          partes.forEach((parte) => {
            parte.select = false;
          });
          this.partesListBody = partes;
          let total_usd = parseInt(this.sumaColumna("total"));
          this.facturacion.total_letras = this.numeroALetras(total_usd).replace(
            "TEINTA",
            "TREINTA"
          );
          this.facturacion.numero = response.data.folio;
          this.facturacion.id = response.data.facturas_id;
          this.facturacion.temporal = response.data.temporal;
          this.ocultarLoading();
          this.loadAcuerdo();
        })
        .catch((error) => {
          console.log(error);
          this.ocultarLoading();
          let response = error.response;
          //let msg = response.data.data;
          this.validation_message =
            response.status + ": " + response.data.message;
          this.showSnackBar(true);
        });
      this.spinner = false;
    },
    async loadAcuerdo() {
      let url = `${this.base_url}contratos/porcliente/${this.facturacion.empresa.id}`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.facturacion.fecha = this.moment().format("YYYY-MM-DD");
          this.facturacion.emision = this.moment().format("YYYY-MM-DD");
          this.facturacion.dias = response.data.dias_pago;
          this.facturacion.vencimiento = this.moment(this.facturacion.emision)
            .add(this.facturacion.dias, "days")
            .format("YYYY-MM-DD");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveItem(item) {
      let d = {
        facturas_id: item.facturas_id,
        partes_id: item.id,
        fecha: item.zarpe,
        wellboat: item.wellboat,
        guia: item.guia,
        centro: item.origen,
        jaula_origen: item.jaula_origen,
        acopio: item.destino,
        jaula_destino: item.jaula_destino,
        especie: item.especie,
        peces_centro: item.peces,
        biomasa: item.biomasa,
        carga_espera: item.espera_carga,
        carga_duracion: item.tiempo_carga,
        traslado_espera: item.espera_traslado,
        traslado_duracion: item.tiempo_traslado,
        descarga_espera: item.espera_descarga,
        descarga_duracion: item.tiempo_descarga,
        tiempo_total: item.horas,
        tarifa_usd: item.tarifa_viaje,
        demora_usd: item.demurrage,
        servicio_usd: item.total,
        millas: item.digitar_millas,
        observaciones: item.observaciones,
        talla_pez_centro: item.peso,
        toneladas_wellboat: item.toneladas_wellboat,
      };

      await this.axios
        .post(`${this.base_url}factura_detalle/guardar`, d, this.headers_json)
        .then((r) => {
          console.log("guardado", r);
          this.showSnackBar(false);
        });
      console.log("item", item, d);
    },
    limipar_filtros() {
      const month = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      const d = new Date();
      let name = month[d.getMonth()];

      this.facturacion = {
        year: this.moment().format("YYYY"),
        month: { id: d.getMonth(), name: name },
        empresa: null,
        numero: 0,
        total_letras: 0,
        fecha: this.moment().format("YYYY-MM-DD"),
        emision: this.moment().format("YYYY-MM-DD"),
        vencimiento: "",
        dias: 0,
        cierre: {},
        tipo_partes_id: null,
      };

      this.partesListBody = [];

      this.inicio = null;
      this.termino = null;
    },
    async saveFactura(temporal) {
      // validation
      this.errors = [];
      if (!this.facturacion.year) {
        this.errors.push("Debe elegir un año");
      }
      if (!this.facturacion.month) {
        this.errors.push("Debe elegir un mes");
      }
      if (!this.facturacion.empresa) {
        this.errors.push("Debe elegir un cliente");
      }
      if (!this.facturacion.numero > 0) {
        this.errors.push("El numero de prefactura debe ser mayor que cero");
      }
      let detalles = [];
      let fila = 1;
      //console.log(this.partesListBody);
      this.partesListBody.forEach((p) => {
        let d = {
          facturas_id: 0,
          partes_id: p.id,
          fecha: p.zarpe,
          wellboat: p.wellboat,
          guia: p.guia,
          centro: p.origen,
          jaula_origen: p.jaula_origen,
          acopio: p.destino,
          jaula_destino: p.jaula_destino,
          especie: p.especie,
          peces_centro: p.peces,
          biomasa: p.biomasa,
          carga_espera: p.espera_carga,
          carga_duracion: p.tiempo_carga,
          traslado_espera: p.espera_traslado,
          traslado_duracion: p.tiempo_traslado,
          descarga_espera: p.espera_descarga,
          descarga_duracion: p.tiempo_descarga,
          tiempo_total: p.horas,
          tarifa_usd: p.tarifa_viaje,
          demora_usd: p.demurrage,
          servicio_usd: p.total,
          //millas: p.digitar_millas,
          millas: p.millas,
          digitar_millas: p.digitar_millas,

          observaciones: p.observaciones,
          talla_pez_centro: p.peso,
          toneladas_wellboat: p.toneladas_wellboat,
        };
        detalles.push(d);
        let demu = parseFloat(p.demurrage);
        if (isNaN(demu) || demu < 0) {
          this.errors.push(
            "Demurrage (fila: " + fila + ") debe ser un número y mayor que cero"
          );
        }
        fila++;
      });
      if (this.errors.length > 0) {
        this.validation_message = this.errors.join(".");
        this.showSnackBar(true);
        return;
      }
      let url = `${this.base_url}facturas`;
      let facturaData = {
        main: {
          cierres_id: this.facturacion.cierre.id,
          fecha: this.facturacion.fecha,
          emision: this.facturacion.emision,
          vencimiento: this.facturacion.vencimiento,
          empresas_id: this.facturacion.empresa.id,
          numero: this.facturacion.numero,
          temporal: temporal,
          tipo_partes_id: this.facturacion.tipo_partes_id,
        },
        detalles: detalles,
        total_letras: this.facturacion.total_letras,
      };
      let response = null;
      try {
        response = await this.axios.post(url, facturaData, this.headers_json);
        this.showSnackBar(false);
        if (temporal == 0) {
          this.limipar_filtros();
        }
      } catch (error) {
        response = error.response;
        let msg = response.data.data;
        this.validation_message =
          response.status + ": " + response.data.message + ". " + msg;
        this.showSnackBar(true);
      }
      if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
        window.open(this.storage_url + response.data.url_pdf);
      }
    },

    calcularTarifa2(item) {
      const index = this.partesListBody.findIndex(
        (p) => p.partes_id == item.partes_id
      );
      console.log("tarifa_viaje", item);
      let serv_usd = parseFloat(item.tarifa_viaje) + parseFloat(item.demurrage);
      item.total = Math.round(serv_usd); //.toFixed(2);
      if (item.digitar_millas == 0) {
        item.tarifa_viaje = 0;
        item.total = 0;
      }
      this.$set(this.partesListBody, index, item);
    },
    async calcularTarifa(item) {
      console.log("item", item);
      if (item.tarifa_viaje != 0) {
        let url = `${this.base_url}tarifas/byclientemilla/${item.tipo_acuerdos_id}/${item.empresas_id}/${item.digitar_millas}`;
        await this.axios
          .get(url, this.headers_json)
          .then((response) => {
            //const index = this.partesListBody.findIndex((p) => p.id == item.id);
            const index = this.partesListBody.findIndex(
              (p) => p.partes_id == item.partes_id
            );
            let tarifa = response.data;
            console.log(tarifa);
            if (tarifa.valor == undefined) {
              tarifa.valor = 0;
              this.validation_message = "No se encontró tarifa!";
              this.showSnackBar(true);
            }
            //let tarifa_viaje = tarifa.valor * item.wellboat.toneladas;
            let tarifa_viaje = tarifa.valor * item.toneladas_wellboat;
            item.tarifa_viaje = Math.round(tarifa_viaje, 2); //.toFixed(2);
            let serv_usd = tarifa_viaje + parseFloat(item.demurrage);
            item.total = Math.round(serv_usd); //.toFixed(2);
            if (item.digitar_millas == 0) {
              item.tarifa_viaje = 0;
              item.total = 0;
            }
            this.$set(this.partesListBody, index, item);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async recalcularTarifa() {
      let items = this.partesListBody
        .filter((i) => {
          return i.tarifa_viaje != 0;
        })
        .map((fila) => {
          return {
            id: fila.id,
            folio: fila.folio,
            partes_id: fila.partes_id,
            digitar_millas: fila.digitar_millas,
            contratos_id: fila.contratos_id,
            tipo_acuerdos_id: fila.tipo_acuerdos_id,
            empresas_id: fila.empresas_id,
            toneladas_coho: fila.toneladas_coho,
            toneladas_salar: fila.toneladas_salar,
            especie: fila.especie,
            demurrage: fila.demurrage,
            tarifa_viaje: fila.tarifa_viaje,
            wellboats_id: fila.wellboats_id,
            total_horas: fila.horas,
          };
        });
      let url = `${this.base_url}facturacion/recalcular_tarifas`;
      await this.axios
        .post(url, { data: items }, this.headers_json)
        .then((response) => {
          //console.log('response',response.data)
          response.data.forEach((i) => {
            let idx = this.partesListBody.findIndex(
              (el) => el.partes_id == i.partes_id
            );
            this.$set(this.partesListBody[idx], "tarifa_viaje", i.tarifa_viaje);
            this.$set(this.partesListBody[idx], "total", i.servicio_usd);
            this.$set(this.partesListBody[idx], "demurrage", i.demurrage);
            if (i.partes_id == 8080) {
              console.log("servicio_usd", i.servicio_usd);
            }
          });
        });
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
        //this.cleanForm();
      }
      this.snackbar = true;
    },
    formatNumber(numero, decimales = 2) {
      //, checknegative = false){
      //if(checknegative && numero < 0) return 'Número Inválido';
      //console.log(`num: (${numero})`);
      if (numero) {
        let arrNum = numero.toString().split(".");
        if (arrNum[1] == undefined) arrNum[1] = "";
        let num = arrNum[0];
        if (!isNaN(num)) {
          num = num
            .split("")
            .reverse()
            .join("")
            .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
          num = num
            .split("")
            .reverse()
            .join("")
            .replace(/^[.]/, "");
          if (decimales > 0) num += ",";
          return num + arrNum[1].padEnd(decimales, "0");
        } else {
          return "0";
        }
      }
      return "0";
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async uploadFacturas() {
      this.headers.headers["Content-Type"] = "multipart/form-data";
      let url = `${this.base_url}uploads/facturas`;
      let formData = new FormData();
      formData.append("excel", this.file);
      formData.append("cierres_id", this.cierre.id);
      let response = null;
      try {
        response = await this.axios.post(url, formData, this.headers);
        this.file = null;
        this.upload = false;
        this.showSnackBar(false);
      } catch (error) {
        response = error.response;
        let msg = response.data.data;
        this.validation_message =
          response.status + ": " + response.data.message + ". " + msg;
        this.showSnackBar(true);
      }
    },
    ...mapActions(["iniciarSession"]),
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
  },
  computed: {
    ...mapState(["base_url", "headers", "headers_json", "storage_url"]),
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
  },
};
</script>
